import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// importanje samo onih komponenti koje trebamo iz Vue Bootstrap-a
import {
    CarouselPlugin,
    CollapsePlugin,
    LayoutPlugin,
    VBScrollspyPlugin,
    ListGroupPlugin
} from "bootstrap-vue";
import VueMq from "vue-mq";
import axios from "axios";
import VueLazyload from "vue-lazyload";
import inViewportDirective from "vue-in-viewport-directive";

import VueGtag from "vue-gtag";

import SocialIcons from "./components/SocialIcons";

import Sticky from "vue-sticky-directive";
Vue.directive("Sticky", Sticky);

// global scss
import "@/assets/sass/application.scss";
// loading indicator
import loadingImg from "./assets/img/loading.svg";

// kad se razvija, ovo je false, kad se builda, ovo je true
const isProd = process.env.NODE_ENV === "production";

Vue.directive("in-viewport", inViewportDirective);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(LayoutPlugin);
Vue.use(VBScrollspyPlugin);
Vue.use(ListGroupPlugin);

Vue.use(VueMq, {
    breakpoints: {
        // default breakpoints - customize this
        xs: 576,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1440,
        xxl: Infinity
    }
});
Vue.use(VueLazyload, {
    loading: loadingImg
});

Vue.use(
    VueGtag,
    {
        config: { id: "GTM-W4ZQH2S" }
    },
    router
);

Vue.component("social-icons", SocialIcons);

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://admin.wearealive.com/";
// axios.defaults.baseURL = "https://wearealive2019-admin.north2.work/";

// prije pokretanja Vue aplikacije - napuniti store sa kategorijama i svim projektima
const categories = axios.get(
    "wp-json/wp/v2/categories?per_page=100&_fields=id,count,name,slug,parent"
);
const projects = axios.get(
    "wp-json/wp/v2/work?per_page=100&_fields=id,slug,categories,acf"
);

Promise.all([categories, projects]).then(res => {
    store.dispatch("setCategories", res[0].data);
    store.dispatch("setProjects", res[1].data).then(() => {
        // nakon što se učitaju potrebni podaci, inicijalizira se Vue aplikacija
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount("#app");
    });
});
