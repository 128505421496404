import Vue from "vue";
import Router from "vue-router";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const Home = () => import("./views/Home.vue");
// const Workflow = () => import("./views/Workflow.vue");
const ourStory = () => import("./views/ourStory.vue");
const Work = () => import("./views/Work.vue");
const workDetail = () => import("./views/workDetail.vue");
const moodboardDetail = () => import("./views/moodboardDetail.vue");
const Services = () => import("./views/Services.vue");
const Contact = () => import("./views/Contact.vue");
const Workflow = () => import("./views/Workflow.vue");
const Moodboard = () => import("./views/Moodboard.vue");
// const Thanks = () => import("./views/Thanks.vue");

Vue.use(Router);

let workScrollPosition = { x: 0, y: 0 };

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/work",
            name: "work",
            component: Work
        },
        {
            path: "/work/:slug",
            name: "work-detail",
            component: workDetail
        },
        {
            path: "/services/:slug",
            name: "services",
            component: Services
        },
        {
            path: "/contact",
            name: "contact",
            component: Contact
        },
        {
            path: "/workflow",
            name: "workflow",
            component: Workflow
        },
        {
            path: "/moodboard",
            name: "moodboard",
            component: Moodboard
        },
        {
            path: "/moodboard/:slug",
            name: "moodboard-detail",
            component: moodboardDetail
        },
        // {
        //     path: "/thank-you",
        //     name: "thanks",
        //     component: Thanks
        // },
        {
            path: "/our-story",
            name: "our-story",
            component: ourStory
        },
        {
            path: "/clients",
            redirect: "/"
        },
        {
            path: "/faq",
            redirect: "/"
        },
        {
            path: "/404",
            name: "error",
            component: () => import("./views/404.vue")
        },
        {
            path: "*",
            redirect: "/404"
        }
    ],
    // da se vrati na vrh page-a nakon 300ms - inače se vidjelo da ide na vrh pa tek onda ide na sljedeći page
    scrollBehavior(to, from, savedPosition) {
        if (!savedPosition) {
            // going forward
            workScrollPosition = {
                x: 0,
                y: window.scrollY
            };

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        x: 0,
                        y: 0
                    });
                }, 300);
            });
        } else {
            // going back
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(workScrollPosition);
                }, 300);
            });
        }
    }
});
