<template>
    <div
        id="app"
        class="bg-primary"
        v-if="appMounted"
        :class="{ scrolled: !topPageState }"
        sticky-container
    >
        <header class="header bg-black d-flex align-items-center">
            <div class="container-longer">
                <div class="row">
                    <div class="col-auto">
                        <router-link
                            to="/"
                            class="logo-wrapper h-100 d-flex align-items-center"
                        >
                            <img
                                src="./assets/img/logo.svg"
                                class="logo-img"
                                alt="We Are Alive"
                            />
                        </router-link>
                    </div>
                    <div class="col-auto col-lg-8 ml-auto">
                        <transition
                            name="fade"
                            @afterEnter="
                                ['xs', 'sm', 'md'].includes($mq)
                                    ? (navOpened = true)
                                    : ''
                            "
                            @afterLeave="
                                ['xs', 'sm', 'md'].includes($mq)
                                    ? (navOpened = false)
                                    : ''
                            "
                        >
                            <nav
                                v-if="navState"
                                id="nav"
                                class="nav justify-content-center justify-content-lg-end align-items-center flex-column flex-lg-row"
                            >
                                <div
                                    v-show="navOpened"
                                    class="d-flex flex-column align-items-center flex-lg-row nav-links"
                                    :class="
                                        ['xs', 'sm', 'md'].includes($mq) &&
                                        navOpened
                                            ? 'visible'
                                            : ''
                                    "
                                >
                                    <router-link
                                        class="nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block"
                                        to="/"
                                        data-anim-stagger-fade
                                        >Home</router-link
                                    >
                                    <router-link
                                        class="nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block"
                                        to="/work"
                                        data-anim-stagger-fade
                                        >Work</router-link
                                    >
                                    <router-link
                                        class="nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block"
                                        to="/our-story"
                                        data-anim-stagger-fade
                                        >Our Story</router-link
                                    >
                                    <!-- <router-link
                                        class="nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block"
                                        to="/workflow"
                                        data-anim-stagger-fade
                                        >Workflow</router-link
                                    > -->
                                    <router-link
                                        class="nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block"
                                        to="/moodboard"
                                        data-anim-stagger-fade
                                        >Moodboard</router-link
                                    >
                                    <span data-anim-stagger-fade>
                                        <Button
                                            url="contact"
                                            bgColor="yellow"
                                            :spacing="false"
                                            class="nav-btn"
                                            >Contact</Button
                                        >
                                    </span>
                                </div>
                            </nav>
                        </transition>
                        <mq-layout
                            :mq="['xs', 'sm', 'md']"
                            class="hamburger-wrapper text-right"
                        >
                            <div
                                class="hamburger hamburger--slider"
                                :class="navState ? 'is-active' : ''"
                            >
                                <div class="hamburger-box" @click="toggleNav">
                                    <div class="hamburger-inner"></div>
                                </div>
                            </div>
                        </mq-layout>
                    </div>
                </div>
            </div>
        </header>

        <transition name="page-fade" @afterLeave="afterLeave">
            <router-view class="dynamic-content" />
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "./components/Button.vue";

export default {
    data() {
        return {
            // za 1.load da se animira
            appMounted: false,
            scrolling: false,
            navOpened: null
        };
    },
    computed: {
        ...mapGetters(["navState", "topPageState", "loading"])
    },
    components: {
        Button
    },
    methods: {
        toggleNav() {
            this.$store.dispatch("setNavState", !this.navState);
        },
        // close navigation after page fade animation finishes - only on mobile
        afterLeave() {
            this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
                ? this.$store.dispatch("setNavState", false)
                : "";
        }
    },
    watch: {
        // na 1.load se odvrti
        $mq(newVal, oldVal) {
            if (newVal !== "xs" && newVal !== "sm" && newVal !== "md") {
                this.$store.dispatch("setNavState", true);
                this.navOpened = true;
            } else {
                this.$store.dispatch("setNavState", false);
                this.navOpened = false;
            }
        }
    },
    mounted() {
        // za inicijalnu animaciju
        this.appMounted = true;

        // scroll handler:
        var vm = this;
        $(window).scroll(function() {
            vm.scrolling = true;

            setInterval(function() {
                if (vm.scrolling) {
                    vm.scrolling = false;
                    // Do your thang!
                    if ($(window).scrollTop() > 100)
                        vm.$store.dispatch("setScrollFlag", false);
                    else vm.$store.dispatch("setScrollFlag", true);
                }
            }, 400);
        });
    }
};
</script>
