<template>
    <!-- currently, it only works for internal links -->
    <router-link
        :to="`/${url}`"
        class="btn text-grayish"
        :class="`bg-${bgColor} ${spacing ? 'my-lg-4' : ''}`"
        ><slot></slot
    ></router-link>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: "/"
        },
        bgColor: {
            type: String,
            default: "blue"
        },
        spacing: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
