var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appMounted)?_c('div',{staticClass:"bg-primary",class:{ scrolled: !_vm.topPageState },attrs:{"id":"app","sticky-container":""}},[_c('header',{staticClass:"header bg-black d-flex align-items-center"},[_c('div',{staticClass:"container-longer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"logo-wrapper h-100 d-flex align-items-center",attrs:{"to":"/"}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("./assets/img/logo.svg"),"alt":"We Are Alive"}})])],1),_c('div',{staticClass:"col-auto col-lg-8 ml-auto"},[_c('transition',{attrs:{"name":"fade"},on:{"afterEnter":function($event){['xs', 'sm', 'md'].includes(_vm.$mq)
                                ? (_vm.navOpened = true)
                                : ''},"afterLeave":function($event){['xs', 'sm', 'md'].includes(_vm.$mq)
                                ? (_vm.navOpened = false)
                                : ''}}},[(_vm.navState)?_c('nav',{staticClass:"nav justify-content-center justify-content-lg-end align-items-center flex-column flex-lg-row",attrs:{"id":"nav"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navOpened),expression:"navOpened"}],staticClass:"d-flex flex-column align-items-center flex-lg-row nav-links",class:['xs', 'sm', 'md'].includes(_vm.$mq) &&
                                    _vm.navOpened
                                        ? 'visible'
                                        : ''},[_c('router-link',{staticClass:"nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block",attrs:{"to":"/","data-anim-stagger-fade":""}},[_vm._v("Home")]),_c('router-link',{staticClass:"nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block",attrs:{"to":"/work","data-anim-stagger-fade":""}},[_vm._v("Work")]),_c('router-link',{staticClass:"nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block",attrs:{"to":"/our-story","data-anim-stagger-fade":""}},[_vm._v("Our Story")]),_c('router-link',{staticClass:"nav-link mb-3 mb-lg-0 mr-lg-2 mr-xl-5 d-inline-block",attrs:{"to":"/moodboard","data-anim-stagger-fade":""}},[_vm._v("Moodboard")]),_c('span',{attrs:{"data-anim-stagger-fade":""}},[_c('Button',{staticClass:"nav-btn",attrs:{"url":"contact","bgColor":"yellow","spacing":false}},[_vm._v("Contact")])],1)],1)]):_vm._e()]),_c('mq-layout',{staticClass:"hamburger-wrapper text-right",attrs:{"mq":['xs', 'sm', 'md']}},[_c('div',{staticClass:"hamburger hamburger--slider",class:_vm.navState ? 'is-active' : ''},[_c('div',{staticClass:"hamburger-box",on:{"click":_vm.toggleNav}},[_c('div',{staticClass:"hamburger-inner"})])])])],1)])])]),_c('transition',{attrs:{"name":"page-fade"},on:{"afterLeave":_vm.afterLeave}},[_c('router-view',{staticClass:"dynamic-content"})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }