import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        navState: false, // otvorena/zatvorena navigacija
        categories: [], //kategorije projekata,
        projects: [], //svi projekti,
        pageOnTop: true,
        loading: true
    },
    mutations: {
        // otvaranje/zatvaranje navigacije
        SET_NAV_STATE(state, bool) {
            state.navState = bool;
        },
        SET_CATEGORIES(state, data) {
            // na 1. mjesto stavljamo Show all kategoriju
            data.unshift({
                id: 0,
                name: "Show all"
            });
            state.categories = data;
        },
        SET_PROJECTS(state, data) {
            state.projects = data;
        },
        SET_SCROLL_FLAG(state, bool) {
            state.pageOnTop = bool;
        },
        SET_LOADING(state, bool) {
            state.loading = bool;
        }
    },
    actions: {
        // za provjeru da li je stranica na vrhu ili se scrollalo
        setScrollFlag({ commit }, bool) {
            commit("SET_SCROLL_FLAG", bool);
        },
        // zatvori/otvori navigaciju
        setNavState({ commit }, bool) {
            commit("SET_NAV_STATE", bool);
        },
        // postavi kategorije projekata
        setCategories({ commit }, data) {
            commit("SET_CATEGORIES", data);
        },
        // postavi projekte
        setProjects({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit("SET_PROJECTS", data);
                resolve();
            });
        },
        setLoading({ commit }, bool) {
            commit("SET_LOADING", bool);
        }
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        topPageState(state) {
            return state.pageOnTop;
        },
        navState(state) {
            return state.navState;
        },
        // all categories
        categories(state) {
            return state.categories;
        },
        // mainCategories - prikazuju se na homepageu/work page-u u obliku filtera
        mainCategories(state) {
            return state.categories.filter(category => {
                return [0, 3, 4, 5, 9, 10, 8, 7, 11, 12].includes(category.id);
            });
        },
        // vraća kategoriju prema poslanom id-u
        categoriesById(state) {
            return id => {
                return state.categories.filter(category => {
                    return category.id === id;
                });
            };
        },
        projects(state) {
            return state.projects;
        }
    }
});
